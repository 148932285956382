/* Fonts */
@font-face {
  font-family: "Poppins";
  font-weight: normal;
  font-style: normal;
  src: url("./Assets/Fonts/Poppins-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #7b8389;
}

a {
  text-decoration: none !important;
}

a:hover {
  color: #7b8389 !important;
}

/* Scrollbar */
body::-webkit-scrollbar {
  width: 4px;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px #ccc;
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  outline: 1px solid slategrey;
  border-radius: 4px;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px #ccc;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  outline: 1px solid slategrey;
  border-radius: 4px;
}

/* Alignment */
.center-align {
  text-align: center;
}

.left-align {
  text-align: left;
}

.right-align {
  text-align: right;
}

/* Grid */
.row {
  margin-left: 0px;
  margin-right: 0px;
}

/* Input fields */
label {
  color: #7b8389 !important;
  font-size: 12px !important;
}

.form-control {
  color: #7b8389 !important;
  font-size: 12px !important;
}

.input-group .input-group-text {
  border: none !important;
}

.input-group-prepend {
  width: 40px;
}

.input-group-prepend svg {
  margin: 0 auto;
  margin-top: 10px;
  color: #666;
}

/* Input field placeholder */
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #7b8389 !important;
  opacity: 0.5 !important;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #7b8389 !important;
  opacity: 0.5 !important;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #7b8389 !important;
  opacity: 0.5 !important;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #7b8389 !important;
  opacity: 0.5 !important;
}

/* Buttons */
.btn {
  height: 40px !important;
  width: 100%;
  padding: 10px;
  text-transform: capitalize;
  border: 1px solid #76b9a9 !important;
  border-radius: 5px !important;
  font-size: 12px;
  background: #66d2b5;
  box-shadow: 1px 11px 15px -13px #bee5e2 !important;
}

/* Modal */
.modal-header {
  padding: 0.75rem;
  border-bottom: 1px solid #f2f2f2 !important;
}

.modal-title {
  font-size: 1.05em;
  text-transform: capitalize;
  letter-spacing: 2px;
}

/* React Select (dropdown) styling */

.select-styling div {
  border-radius: 5px !important;
  color: #7b8389 !important;
  font-size: 12px !important;
}

/* Form phone number */

.react-tel-input input[type="tel"],
.react-tel-input input[type="text"] {
  width: 100% !important;
  padding-left: 48px !important;
  background: #ffffff !important;
  color: #7b8389 !important;
  border-radius: 5px !important;
  font-size: 12px !important;
}

.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: none !important;
}

/* Sidebar menu */

.custom-sidebar {
  background: #1e242a !important;
}

.custom-sidebar ul li a.active {
  color: #ffffff !important;
}
